<template>
  <DefaultTemplate :showStep="formFlag">
    <div class="depositFunds-box">
      <div class="form-box" v-if="formFlag">
        <el-form label-position="top" :model="cpsForm" ref="cpsForm" status-icon :rules="cpsRules">
          <div class="box_top">
            <div class="box_top_left box">
              <p>
                {{ langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.description_title) }}
              </p>
              <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
              <strong>{{ $t('deposit.default.deposit') }}</strong>
              <div class="info">
                <p v-for="(item, index) in depositNote" :key="`deposit_info_${index}`">
                  {{ item }}
                </p>
              </div>
              <el-form-item :label="$t('common.field.accNum')" prop="accountNumber">
                <el-select
                  v-model="cpsForm.account"
                  :no-data-text="$t('common.field.noEligibleAcc')"
                  data-testid="accountNumber"
                >
                  <el-option
                    v-for="item in accountNumberOptions"
                    :key="item.value"
                    :value="item.value"
                    :data-testid="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- BANK INFO -->
              <div class="bank_info" v-if="showBankInfo">
                <ul v-for="{ title, info } in bankInfo" :key="title">
                  <li v-if="info.show">
                    <span class="title_name">{{ langTranslate('common.field.', title) }}</span>
                    <span class="value_info">{{ info.value }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="box_top_right">
              <div class="logo circle bank"></div>
            </div>
          </div>
          <div class="box_bottom box">
            <div class="warn_info">
              <p v-for="(item, index) in depositWarnInfo" :key="`warn_info_${index}`">
                {{ item }}
              </p>
            </div>
            <strong>{{ $t('deposit.default.sendReceipt') }}</strong>
            <div class="info">
              <p v-for="(item, index) in fromInfo" :key="`from_info_${index}`">{{ item }}</p>
            </div>
            <div class="form-list">
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.accNum')" class="special_input">
                    <div class="special_input_demo">{{ cpsForm.accountNumber }}</div>
                  </el-form-item>
                </li>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.amt')" prop="amount">
                    <numeric-input
                      v-model="cpsForm.amount"
                      :currency="cpsForm.currency"
                      :precision="2"
                      :readOnly="isDisabled"
                    ></numeric-input>
                  </el-form-item>
                  <p>
                    {{
                      $t('deposit.default.rate.rate', {
                        oldCurrency: cpsForm.currency,
                        newCurrency: paymentDetails.actual_currency_code
                      })
                    }}
                    <span> {{ rate }}</span>
                  </p>
                  <p>
                    {{ `${paymentDetails.actual_currency_code}: ${rateChange} ` }}
                  </p>
                </li>
              </ul>
              <ul class="clearfix">
                <template v-if="cpsForm.attachVariables.length > 0">
                  <li v-for="(type, index) in cpsForm.attachVariables" :key="type.key">
                    <!-- Email Field -->
                    <el-form-item
                      v-if="type.field_type === 'input_field' && type.type === 'email'"
                      :label="getFieldLabel(type.field_name)"
                      prop="email"
                    >
                      <el-input
                        v-model="cpsForm.attachVariables[index].value"
                        :disabled="disabled"
                        :data-testid="type.type"
                      ></el-input>
                    </el-form-item>

                    <!-- Input Field -->
                    <DynamicRestrictInput
                      v-else-if="type.field_type == 'input_field'"
                      :label="getFieldLabel(type.field_name)"
                      v-model="cpsForm.attachVariables[index].value"
                      :name="`attachVariables.${index}.value`"
                      :restrictionType="type.restriction_type"
                      :maxlength="type.field_length"
                      :testId="type.type"
                    >
                    </DynamicRestrictInput>

                    <!-- Dropdown List -->
                    <el-form-item
                      v-if="type.field_type == 'dropdown_list' && type.type == 'depositor'"
                      :label="getFieldLabel(type.field_name)"
                      prop="select"
                    >
                      <el-select
                        v-model="cpsForm.attachVariables[index].value"
                        :placeholder="$t('common.field.select')"
                        :data-testid="type.key"
                      >
                        <el-option
                          v-for="(value, name) in type.field_content"
                          :label="value.desc"
                          :value="value.value"
                          :key="name"
                        ></el-option>
                      </el-select>
                    </el-form-item>

                    <!-- Dropdown List For Bank Name-->
                    <el-form-item
                      v-else-if="type.field_type == 'dropdown_list' && type.type == 'bank'"
                      :label="$t('common.field.bankName')"
                      prop="select"
                    >
                      <el-select
                        v-model="cpsForm.attachVariables[index].value"
                        :placeholder="$t('common.field.select')"
                        :data-testid="type.key"
                      >
                        <el-option
                          v-for="(value, name) in type.field_content"
                          :label="value.bank_name"
                          :value="value.bank_code"
                          :key="name"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </li>
                </template>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.upload')" prop="uploadFile">
                    <vUpload :limit="6" v-on:updateFileInfo="updateFileInfo" data-testid="updateFileInfo"></vUpload>
                  </el-form-item>
                </li>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.notes')">
                    <el-input v-model="cpsForm.notes" data-testid="notes"></el-input>
                  </el-form-item>
                </li>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-button
                    class="btn-blue"
                    :loading="loading"
                    :disabled="loading"
                    @click="submitForm()"
                    data-testid="submit"
                  >
                    {{ $t('common.button.submit') }}
                  </el-button>
                </li>
              </ul>
            </div>
          </div>
        </el-form>
      </div>
      <Result v-if="successFlag">{{ $t('deposit.default.successMsg') }}</Result>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo';
import DynamicRestrictInput from '@/components/form/DynamicRestrictInput';
import mixin from '@/mixins/page/deposit';
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';
import { apiQuery_mt_accounts } from '@/resource';
import { apiCps_payment } from '@/resource/cps';
import fieldLabelList from '@/constants/depositFieldLabel';
import rounding from '@/util/rounding';
import { validateEmail } from '@/util/validation';
import { countryCodeEnum } from '@/constants/country';

export default {
  name: 'internationalCPS',
  components: { vUpload, NumericInput, Result, DefaultTemplate, DepositInfo },
  mixins: [mixin, cpsMixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimitByCurrency) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimitByCurrency),
              currency: this.cpsForm.currency
            })
          )
        );
      } else if (parseFloat(value) > this.maxLimitByCurrency) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', {
              maxLimit: this.maxLimitByCurrency,
              currency: this.cpsForm.currency
            })
          )
        );
      } else {
        callback();
      }
    };
    const checkUploadFile = (rule, value, callback) => {
      if (!(this.fileList.length > 0)) {
        callback(new Error(this.$t('common.formValidation.fileReceipt')));
      }
      callback();
    };
    return {
      cpsForm: {
        account: '',
        amount: '',
        accountNumber: '',
        currency: '',
        notes: '',
        attachVariables: null
      },
      cpsRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        uploadFile: [
          {
            validator: checkUploadFile,
            required: true,
            trigger: 'change'
          }
        ],
        email: [
          {
            required: true,
            validator: validateEmail,
            message: this.$t('common.formValidation.emailReq'),
            trigger: 'blur'
          }
        ],
        input: [
          {
            required: true,
            message: this.$t('common.formValidation.common'),
            trigger: 'blur'
          }
        ],
        select: [
          {
            required: true,
            message: this.$t('common.formValidation.common'),
            trigger: 'change'
          }
        ]
      },
      accountNumberOptions: [],
      accountCurrency: '',
      fileList: [],
      showBankInfo: false,
      formFlag: true,
      successFlag: false,
      showNote: false,
      rate: 0.0,
      isDisabled: false,
      exchangeRates: false,
      showExchangeRates: false
    };
  },
  mounted() {
    this.fetchTradingAccount();
  },
  watch: {
    'cpsForm.account'(value) {
      const values = value.split(' - ');
      this.cpsForm.accountNumber = values[0];
      this.cpsForm.currency = values[1];
      this.accountCurrency = values[1];
      this.showBankInfo = true;
    },
    'cpsForm.currency': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.determineExchangeRate();
        }
      }
    },
    accountNumberOptions(options) {
      // 預設第一筆
      if (options.length > 0) this.cpsForm.account = options[0].value;
    }
  },
  computed: {
    country() {
      return parseInt(this.$store.state.common.countryCode);
    },
    instData() {
      if (
        this.paymentDetails.payment_method === 'T00600_501' &&
        [countryCodeEnum.UNITED_ARAB_EMIRATES].includes(this.country)
      ) {
        return [this.$t('deposit.reminder.inst14'), this.$t('deposit.reminder.inst16')];
      } else {
        return [
          this.$t('deposit.reminder.inst17'),
          this.$t('deposit.reminder.inst18', {
            time: this.paymentDetails.merchant_variable.processing_time
          })
        ];
      }
    },
    depositNote() {
      if (this.paymentDetails.payment_method === 'T00600_501' && [countryCodeEnum.INDIA].includes(this.country)) {
        return [
          this.$t('deposit.intSwift.desc', { platform: this.$config.info.fullName }),
          this.$t('deposit.intSwift.desc3', { platform: this.$config.info.fullName }),
          this.$t('deposit.intSwift.options')
        ];
      } else {
        // (T00600_501 & United Arab Emirates <USD, AED>)
        return [
          this.$t('deposit.intSwift.desc', { platform: this.$config.info.fullName }),
          this.$t('deposit.intSwift.options')
        ];
      }
    },
    depositWarnInfo() {
      if (this.paymentDetails.payment_method === 'T00600_501' && [countryCodeEnum.INDIA].includes(this.country)) {
        return [
          this.$t('deposit.intSwift.refInfo', { platform: this.$config.info.fullName }),
          this.$t('deposit.default.sendReceipt')
        ];
      } else {
        // (T00600_501 & United Arab Emirates <USD, AED>)
        return [this.$t('deposit.default.sendReceipt')];
      }
    },
    fromInfo() {
      if (this.paymentDetails.payment_method === 'T00600_501' && [countryCodeEnum.INDIA].includes(this.country)) {
        return [$t('deposit.intSwift.desc2')];
      } else {
        // (T00600_501 & United Arab Emirates <USD, AED>)
        return [this.$t('deposit.indiaBankTransfer.desc1')];
      }
    },
    paymentDetails() {
      let attachVariables = Object.values(this.$store.state.cps.payment.attach_variable);
      this.cpsForm.attachVariables = attachVariables.filter(field => field.hasOwnProperty('field_type'));

      return this.$store.state.cps.payment;
    },
    bankInfo() {
      return this.paymentDetails.attach_variable
        .flatMap(({ field_content }) => field_content ?? [])
        .flatMap(({ field_content }) => {
          return (
            field_content.map(({ name, value }) => ({
              title: this.getFieldLabel(name),
              info: { show: true, value }
            })) ?? []
          );
        });
    },
    minLimitByCurrency() {
      const limit = this.paymentDetails.merchant_variable.limit.find(limit => {
        return this.cpsForm.currency === limit.account_currency;
      });
      return parseFloat(limit.min);
    },
    maxLimitByCurrency() {
      const limit = this.paymentDetails.merchant_variable.limit.find(limit => {
        return this.cpsForm.currency === limit.account_currency;
      });
      return parseFloat(limit.max);
    },
    rateChange() {
      return rounding(Math.ceil, this.rate * this.cpsForm.amount, 2);
    },
    ibtBank() {
      return Object.values(this.paymentDetails.attach_variable)
        .filter(field => field.hasOwnProperty('field_type'))
        .filter(({ field_type }) => field_type === 'read_only')[0].field_content[0].value;
    }
  },
  methods: {
    fetchTradingAccount() {
      apiQuery_mt_accounts({ supportedCurrencies: this.paymentDetails.merchant_variable.trade_account }).then(resp => {
        if (resp.data.code == 0) {
          this.accountNumberOptions = resp.data.data.map(i => {
            return {
              accountNumber: i.mt4_account,
              value: i.mt4_account + ' - ' + i.currency,
              currency: i.currency
            };
          });
        }
      });
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['cpsForm'].validateField('uploadFile');
    },
    submitForm() {
      this.$refs['cpsForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0 && result.data.data) {
                this.formFlag = false;
                this.successFlag = true;
              } else {
                this.errorMessage(this.$t('deposit.default.failed'));
                // re-calling anti-reuse token
                this.fetchToken()
                  .then(resp => {
                    this.loading = false;
                  })
                  .catch(resp => {
                    this.loading = false;
                    this.errorMessage(this.$t('resetPassword.failed'));
                  });
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      // Get email_address
      let emailAddressIndex = -1;
      if (this.cpsForm.attachVariables) {
        emailAddressIndex = this.cpsForm.attachVariables.findIndex(function (type) {
          return type.key == 'psp_account';
        });
      }

      // Map attach variables
      let attachVariables = {};
      if (this.cpsForm.attachVariables) {
        this.cpsForm.attachVariables.map(a => {
          if (a.field_type === 'read_only') attachVariables['ibt_bank'] = this.ibtBank;
          else if (a.key === 'file_path') attachVariables[a.key] = this.convertToAttachVariablesFileList(this.fileList);
          else attachVariables[a.key] = a.value;
        });
      }

      const determineActualCurrencyCode = () => {
        return this.accountCurrency === 'USC' ? 'USD' : this.accountCurrency;
      };

      const getCurrencyNumber = (item, currency) => {
        if (item.unique_currency && currency) {
          const uniqueCurrencyConfig = item.unique_currency;
          let configObject = {};
          uniqueCurrencyConfig.forEach(config => {
            if (config.actual_currency_code === currency) {
              configObject = { ...config };
            }
          });
          return configObject.currency_number;
        }
        return item.currency_number;
      };

      const getActualCurrencyNumber = (item, currency) => {
        if (item.unique_currency && currency) {
          const uniqueCurrencyConfig = item.unique_currency;
          let configObject = {};
          uniqueCurrencyConfig.forEach(config => {
            if (config.actual_currency_code === currency) {
              configObject = { ...config };
            }
          });
          return configObject.actual_currency_number;
        }
        return item.actual_currency_number;
      };

      let requestBody = {
        mt4Account: this.cpsForm.accountNumber,
        operateAmount: this.cpsForm.amount,
        applicationNotes: this.cpsForm.notes,
        depositAmount: this.exchangeRates ? this.rateChange : this.cpsForm.amount,
        cpsCode: this.paymentDetails.payment_method,
        orderCurrency: this.paymentDetails.unique_currency
          ? getCurrencyNumber(this.paymentDetails, determineActualCurrencyCode())
          : this.paymentDetails.currency_number,
        actualCurrency: this.paymentDetails.unique_currency
          ? getActualCurrencyNumber(this.paymentDetails, determineActualCurrencyCode())
          : this.paymentDetails.actual_currency_number,
        mandatory: Object.values(this.$store.state.cps.payment.attach_variable)
          .filter(field => field.length > 0)
          .toString(),
        attachVariables: JSON.stringify(attachVariables),
        rate: this.rate,
        email: emailAddressIndex != -1 ? this.cpsForm.attachVariables[emailAddressIndex].value : '',
        ibtBank: this.ibtBank,
        fileList: this.fileList
      };

      return apiCps_payment(requestBody, this.token);
    },
    getFieldLabel(val) {
      const i18nKey = this.getI18nKey(val);
      if (this.$te(i18nKey) || this.$te(i18nKey, 'en_US')) {
        if (i18nKey === 'common.field.paymentAccName' || i18nKey === 'common.field.paymentAccNum')
          return this.$t(i18nKey, { name: val.split(' ')[0] });
        return this.$t(i18nKey);
      }
      return val;
    },
    getI18nKey(val) {
      let label = fieldLabelList[val];
      return label ? label : val;
    },
    convertToAttachVariablesFileList(list) {
      return list.map(el => {
        let url = new URL(el);
        return url.pathname;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/internationalAndAu.scss';
</style>
